export default function AmButton () {
  return (
    <div className="AmButton">

<div className="word">A.M.</div>
<div className="circle"></div>

    </div>
  )
}
