export default function PmButton () {
  return (
    <div className="PmButton">

<div className="circle"></div>
<div className="word wordpm">P.M.</div>


    </div>
  )
}